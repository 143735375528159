import ProhibitBindingColumn from "./ProhibitBindingColumn.vue";

export const tableColumns = {
  countyName: {
    formatDataKey: "County",
    formatDataValue: (arg: string) => arg,
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    position: 0
  },
  countyCode: {
    formatDataKey: "County Code",
    formatDataValue: (arg: string) => arg,
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    position: 1,
    colWidth: 150
  },
  tier: {
    formatDataKey: "Tier",
    formatDataValue: (arg: string) => arg,
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    position: 2,
    colWidth: 125,
    allowInSimpleFilter: true,
    simpleFilter: [
      { label: "Tier 1", value: "Tier 1" },
      { label: "Tier 2", value: "Tier 2" }
    ]
  },
  prohibitBinding: {
    formatDataKey: "Binding Prohibited",
    formatDataValue: (arg: string) => arg,
    formatDataComponent: ProhibitBindingColumn,
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    position: 3,
    allowInSimpleFilter: true,
    simpleFilter: [
      { label: "Yes", value: "true" },
      { label: "No", value: "false" }
    ],
    colWidth: 180
  }
};


import Vue from "vue";
export default Vue.extend({
  name: "county-prohibition-status",
  props: {
    row: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  watch: {
    "row.prohibitBinding": {
      immediate: true,
      handler: "highlightParentElement"
    }
  },
  data() {
    return {
      errorClass: "bg-red-50",
      successClass: "bg-green-50"
    };
  },
  mounted() {
    this.highlightParentElement();
  },
  methods: {
    highlightParentElement() {
      const { theCell } = this.$refs as any;
      if (theCell) {
        const parent = theCell.parentElement.parentElement;
        if (this.row.prohibitBinding) {
          parent.classList.add(this.errorClass);
          parent.classList.remove(this.successClass);
        } else {
          parent.classList.add(this.successClass);
          parent.classList.remove(this.errorClass);
        }
      }
    }
  },
  computed: {
    indicatorClass(): string[] {
      const classes = ["py-1", "px-2"];
      if (this.row.prohibitBinding) {
        classes.push(this.errorClass);
      } else {
        classes.push(this.successClass);
      }
      return classes;
    },
    title(): string {
      return this.row.prohibitBinding ? "Prohibited" : "Allowed";
    }
  }
});
